<template>
  <layout>
    <page-title icon="lni lni-plus" :breadcrumb="breadcrumb">Novo Responsável</page-title>
    <div class="card">
      <div class="card-content">
        <div class="form">
          <div v-if="error" class="notification is-danger field">
            <button class="delete" @click="error = null"></button>
            {{ error }}
          </div>
          <div class="field">
            <label class="label" for="name">Nome<asterisk /></label>
            <div class="control">
              <input v-model="name" class="input" type="text" name="name" id="name" placeholder="Nome do responsável" ref="name" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="email">Email<asterisk /></label>
            <div class="control">
              <input v-model="email" class="input" type="text" name="email" id="email" placeholder="Email do responsável" ref="email" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="password">Senha<asterisk /></label>
            <div class="control">
              <input v-model="password" class="input" type="password" name="password" id="password" placeholder="Senha de acesso" ref="password" />
            </div>
          </div>
          <div class="field">
            <label class="label" for="relationship">Parentesco<asterisk /></label>
            <div class="select is-fullwidth">
              <select v-model="relationship" name="relationship" id="relationship" ref="relationship">
                <option
                  v-for="parent in parentOptions"
                  :key="parent.name"
                  :value="parent.value || parent.name"
                  :selected="parent.value || parent.name === relationship"
                >
                  {{ parent.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="field is-grouped is-grouped-centered mt-5">
            <div class="control">
              <button class="button is-link" :class="{'is-loading': enviando}" @click="send()">Salvar Responsável</button>
            </div>
            <div class="control">
              <router-link class="button is-link is-light" :to="`/admin/alunos/${this.idAluno}/editar`">Cancelar</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import validator from 'validator'
import SwalLoading from '@/helpers/SwalLoading'
import { DASHBOARD, STUDENT_LIST, RESPONSIBLE_FORM } from '@/config/breadcrumb/admin'
import parentsOptions from '@/helpers/parentsOptions'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import Asterisk from '@/components/ui/Asterisk'

export default {
  name: 'ResponsibleForm',
  components: {
    Layout,
    PageTitle,
    Asterisk
  },
  created () {
    this.idAluno = this.$route.params.idAluno

    if (this.$route.params.id !== undefined) {
      SwalLoading.fire()

      api.get(`/responsibles/${this.$route.params.id}/students/${this.$route.params.idAluno}`).then(res => {
        if (res.status === 200) {
          this.id = res.data.id
          this.name = res.data.name
          this.email = res.data.email
          this.password = res.data.password
          this.relationship = res.data.relationship
        } else {
          this.error = 'Não foi possivel carregar o responsável'
        }

        SwalLoading.close()
      }).catch(err => {
        SwalLoading.close()
        if (err.response) {
          this.error = err.response.data.message || 'Não foi possível carregar os dados do responsável'
        } else {
          this.error = 'Servidor não responde'
        }
      })
    }
  },
  data () {
    return {
      idAluno: 0,
      id: 0,
      name: '',
      email: '',
      password: '',
      relationship: '',
      error: null,
      enviando: false
    }
  },
  computed: {
    breadcrumb () {
      return [
        DASHBOARD,
        STUDENT_LIST,
        {
          path: `/admin/alunos/${this.idAluno}/editar`,
          text: 'Editar Aluno'
        },
        { ...RESPONSIBLE_FORM, isActive: true }
      ]
    },
    parentOptions () {
      return parentsOptions
    }
  },
  methods: {
    send () {
      this.enviando = true

      if (this.name === '') {
        return this.fieldInvalid('name', 'Nome é obrigátorio')
      }
      this.error = null

      if (!validator.isEmail(this.email)) {
        return this.fieldInvalid('email', 'Email inválido')
      }
      this.error = null

      if (this.password === '' && this.id === 0) {
        return this.fieldInvalid('password', 'Senha é obrigátoria')
      }
      this.error = null

      if (this.relationship === '') {
        return this.fieldInvalid('relationship', 'Parentesco é obrigátorio')
      }

      if (this.id === 0) {
        api.post('/responsibles', {
          name: this.name,
          email: this.email,
          password: this.password
        }).then(res => {
          if (res.status === 201) {
            api.post(`/responsibles/${res.data.id}/student`, {
              idStudent: this.idAluno,
              relationship: this.relationship
            }).then(() => {
              this.$router.push(`/admin/alunos/${this.idAluno}/editar`)
            })
          } else {
            this.error = 'Responsável não foi criado'
            this.enviando = false
          }
        }).catch(err => {
          this.enviando = false
          if (err.response) {
            this.error = err.response.data.message
          } else {
            this.error = 'Servidor não responde'
          }
        })
      } else {
        api.patch(`/responsibles/${this.id}`, {
          name: this.name,
          email: this.email,
          password: this.password
        }).then(res => {
          if (res.status === 200) {
            api.patch(`/responsibles/${res.data.id}/student`, {
              idStudent: this.idAluno,
              relationship: this.relationship
            }).then(() => {
              this.$router.push(`/admin/alunos/${this.idAluno}/editar`)
            }).catch(() => {
              // this.$router.push(`/admin/alunos/${this.idAluno}/editar`)
            })
          }
        })
      }
    },
    fieldInvalid (ref, message) {
      this.error = message
      this.$refs[ref].focus()
      this.enviando = false
    }
  }
}
</script>
